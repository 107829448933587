// $colorA: #4b4e53;
// $colorA: black;
$colorA: #adadad;
// $colorB: #fdf4e3;
// $colorB: #F2E450;
// $colorB: #f5e41b;
$colorB: black;
$colorC: rgb(80, 134, 169);
$colorD: #D3E3FA;
// $colorE: #50AAF3;
$colorE: #ff5e00;
$colorF: #6a34ff;

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Helvetica', serif;
  cursor: default;
  max-width: 100%;
  overflow-x: hidden;
}

main {
  color: $colorA;
  display: flex;
}

h2 {
  margin: 0;
}

//* NAVBAR

.nav-bar {
  display: flex;
  justify-content: flex-end;
  z-index: 11;
  width: 100%;
  margin: 0;
  position: fixed;
  top: 0;
  right: 0;
  padding: 0;
  color: black;
}

.nav-bar li {
  font-size: 35px;
  list-style: none;
  text-transform: uppercase;
  padding: 3px 15px 3px 3px;
  text-decoration: none;
  font-family: 'Helvetica', serif;
  color: $colorB;
  text-shadow: 1px 0 0 $colorA, 0 -1px 0 $colorA, 0 1px 0 $colorA, -1px 0 0 $colorA;
  cursor: pointer;
  transition: .2s;
}

.bi-emoji-smile-upside-down {
  color: $colorA;
}

@media (max-width: 690px) {
  .nav-bar li {
    font-size: 20px;
    padding: 2px 7px 2px 2px;
  }
}

@media (max-width: 480px) {
  .nav-bar li {
    font-size: 18px;
    padding: 1px 5px 1px 1px;
  }
}


.projects :hover,
.experience :hover,
.skills :hover,
.events :hover,
.contact :hover {
  color: $colorA;
  text-shadow: 1px 0 0 $colorA, 0 -1px 0 $colorA, 0 1px 0 $colorA, -1px 0 0 $colorA;
  transition: .2s;
  stroke: black;
  stroke-width: .2px;
}

.projects :hover {
  color: $colorE;
}

.experience :hover {
  color: white;
}

.skills :hover {
  color: $colorF;
}


.bi-arrow-up {
  text-shadow: 2px 0 0 #000, 0 -2px 0 #000, 0 2px 0 #000, -2px 0 0 #000;
  transition: .2s;
  stroke: $colorA;
  stroke-width: .2px;
}

.bi-arrow-up:hover {
  // stroke: $colorE;
  transform: translate(0px, -10px);
  // stroke-width: .3px;
}



a {
  text-decoration: none;
}

li a {
  color: inherit;
}

.home-button {
  position: fixed;
  left: 0;
}

.bi-emoji-smile-upside-down {
  font-size: 30px;
  margin: 5px 0 0 5px;
  transition: .2s;
}

.bi-emoji-smile-upside-down:hover {
  transform: rotate(180deg);
  stroke: none;
  stroke-width: none;
  transition: .3s;
}

.projects-section,
.experience-section,
.events-section,
.contact-section,
.about-section {
  display: flex;
  width: 100%;
}


.section-divider {
  height: 10px;
  background-color: $colorA;
  margin: 0;
}

.section-divider-white {
  height: 10px;
  background-color: white;
  margin: 0;
  z-index: 10;
  position: relative;
}



//* TITLE PAGE

.about-section {
  justify-content: center;
  flex-direction: column;
  background-color: $colorB;
  text-transform: uppercase;
  z-index: 1;
}

// @keyframes example {
//   0%   {transform: matrix3d(1, 0, 0, 0, 0, 1, 0.3, 0, 0, 0, 1, 0, 0.3, 0, 0.5, 1);}
//   25%   {transform: matrix3d(1, 0, 0, 0, 0, 1, 0.3, 0.0053, 0, 0, 1, 0, 0, 0, 0, 1);}
//   75%   {transform: matrix3d(1, 0, 0, 0, 0, 1, 0.3, -0.0053, 0, 0, 1, 0, 0, 0, 0, 1);}
//   100%   {transform: matrix3d(1, 0, 0, 0, 0, 1, 0.3, 0, 0, 0, 1, 0, 0.3, 0, 0.5, 1);}
//   // 100%  {transform: matrix3d()}
//   // 50%  {color: blue;}
//   // 100% {color: green;}
// }

@keyframes example {
  // 0%   {transform: matrix3d(1, 0, 0, 0, 0, 1, 0.3, 0, 0, 0, 1, 0, 0.3, 0, 0.5, 1);}
  // 50% {transform: matrix3d(1, 0, 0, 0, 0, 1, 0.3, 0, 0, 0, 1, 0, 0.3, 0, 0.5, 1);}
  // 70% {transform: matrix3d(1, 0, 0.2, 0, 0, 1, 0, 0.001, 0.3, 0.5, 1, 0.3, 0.2, 6, 0, 1);}
}

.title-wrapper {
  font-size: 148px;
  // height: 148px;
  font-family: 'Helvetica', serif;
  font-weight: normal;
  transition: .2s;
  // transform: matrix3d(1, 0, 0.2, 0, 0, 1, 0, 0.001, 0.3, 0.5, 1, 0.3, 0.2, 6, 0, 1);
  // animation-name: example;
  // animation: example 6s infinite;
}



.bio-wrapper {
  font-size: 28px;
  text-transform: none;
  font-weight: normal;
  font-family: 'Helvetica', serif;
  transition: .2s;
  line-height: 1.5;
  max-width: 981px;
  // animation: example 6s infinite;
}

.about-section {
  height: 100vh;
  // flex-grow: 1;
}

.title-section {
  // flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
  margin-right: 20px;
  position: fixed;
}

h1 {
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-left: -0.09em;
  // animation: example 60s infinite;
}

@media (max-width: 1000px) {
  .title-wrapper {
    font-size: 105px;
    // height: 45px;
  }

  .bio-wrapper {
    font-size: 20px;
  }
}

@media (max-width: 700px) {
  .title-wrapper {
    font-size: 55px;
  }

  .bio-wrapper {
    font-size: 18px;
  }

  .nav-bar li {
    font-size: 16px;
  }
}

@media (max-width: 350px) {
  .title-wrapper {
    font-size: 35px;
  }

  .bio-wrapper {
    font-size: 12px;
  }
}

.bio-link {
  color: $colorA;
  font-weight: bold;
  text-decoration: underline;
  font-style: italic;
}

.scroll-down-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 10px;
  height: 100vh;
  width: 100vw;
}


.heading {
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-top: 4vh;
  width: 100%;
  font-size: 140px;
  display: flex;
  justify-content: center;
  cursor: default;
  // transform: matrix3d(1, 0, 0, 0, 0, 1, 0.3, 0.0023, 0, 0, 1, 0, 0, 0, 0, 1);
}

@media (max-width: 1000px) {
  .heading {
    font-size: 70px;
  }

  .skills-list li {
    font-size: 18px;
  }
}

@media (max-width: 800px) {
  .heading {
    font-size: 50px;
  }
}

@media (max-width: 480px) {
  .heading {
    font-size: 30px;
  }
}

//* PROJECTS

.projects-section {
  background-color: $colorE;
  flex-direction: column;
  font-family: 'Helvetica', serif;
  color: white;
  position: relative;
  z-index: 10;
}

.project-wrapper {
  display: flex;
  justify-content: center;
}

@media (max-width: 1150px) {
  .project-wrapper {
    flex-direction: column;
  }

  .project-image {
    width: 400px;
    margin: 30px;
  }

  .project-bio-wrapper {
    max-width: 700px;
  }
}



.project-bio {
  font-size: 18px;
  font-family: 'Helvetica', serif;
  border-top: 2px solid white;
  padding-top: 10px;
}

//* Small screen
@media (max-width: 690px) {
  .project-bio {
    font-size: 12px;
  }

  .project-links a {
    font-size: 12px;
  }
}

//* Large screen
@media (min-width: 1200px) {
  .project-bio {
    font-size: 18px;
  }

  .project-bio-wrapper {
    width: 510px;
  }
}


.project-image-wrapper {
  height: auto;
  vertical-align: top;
  // background-color: white;
}

.projects-content-wrapper {
  margin-bottom: 50px;
}

.project-wrapper {
  margin: 50px 150px 50px 130px;
}

.project-title {
  font-size: 35px;
  margin-bottom: 10px;
  text-transform: uppercase;
}

@media (min-width: 1200px) {
  .project-title {
    font-size: 35px;
  }
}

.project-image {
  width: 510px;
  object-fit: cover;
  display: flex;
  border: 6px solid white;
  background-color: white
}

.tags-wrapper {
  display: flex;
  margin: 14px 0;
  width: 100%;
  flex-wrap: wrap;
}

.project-tag,
.experience-tag {
  margin-right: 7px;
  margin-bottom: 4px;
  border-radius: 6px;
  color: white;
  width: min-content;
  padding: 1px 5px;
  font-size: 13px;
  border-style: solid;
  border-width: 1px;
  border-color: white;
  white-space: nowrap;
}

.experience-tag {
  color: black;
  border-color: black;
}


//* Large screen
@media (min-width: 1200px) {
  .project-image {
    width: 510px;
  }
}

//* Small screen
@media (max-width: 690px) {
  .project-image-wrapper {
    background-color: transparent;
  }

  .project-image {
    width: 240px;
  }
}

@media (max-width: 870px) {
  .project-image {
    width: 320px;
  }

  .project-wrapper {
    margin: 30px 0;
  }
}

@media (max-width: 400px) {
  .project-image {
    width: 240px;
  }
}



.project-bio-wrapper {
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  white-space: pre-line;
}

.project-links {
  align-self: flex-end;
  justify-self: flex-start;
  height: 100%;
  display: flex;
  align-items: flex-end;
  padding-right: 5px;
  padding-bottom: 3px;
  font-size: 18px;
}

.project-links a {
  background-color: white;
  color: $colorE;
  font-family: 'Helvetica', serif;
  padding: 3px 5px;
  margin: 30px 0 0 6px;
  border-radius: 2px;
  transition: all .1s ease-in-out; 
  // text-transform: uppercase;
  border-style: solid;
  border-width: 1px;
  border-color: white;
}

.project-links a:hover {
  // transform: scale(1.05);
  // box-shadow: 0 0 10px #fff;
  background-color: $colorE;
  color: white;
  // border-style: solid;
  // border-width: 1px;
  // border-color: white;
}

// .bi-box-arrow-up-right,
// .bi-github {
//   width: 2vw;
//   height: 2vw;
//   margin-left: 2vw;
//   transition: .2s;
// }

.bi-box-arrow-up-right:hover,
.bi-github:hover {
  transition: .2s;
  transform: translate(-2px, -2px);
}


//* EXPERIENCE

.experience-section {
  flex-direction: column;
  background-color: white;
  color: black;
  align-items: center;
  z-index: 10;
  position: relative;
}

.experience-wrapper {
  display: flex;
  margin: 50px 0;
  width: 65%;
}

.experience-image-wrapper {
  height: auto;
  // width: 30vw;
  width: 150px;
  margin: 0 40px;
  display: flex;
  align-items: flex-start;
}

@media (min-width: 1200px) {
  .experience-image-wrapper {
    width: 200px;
  }
}

@media (max-width: 690px) {
  .experience-wrapper {
    flex-direction: column;
    margin: 0;
  }

  .experience-image-wrapper {
    margin: 40px 0;
    width: 100px;
  }
}

.experience-title {
  font-size: 4.5vw;
  margin-bottom: 5px;
  font-weight: normal;
  width: 100%;
}

h4,
h5 {
  margin: 0;
}

.experience-role {
  font-size: 22px;
  margin-bottom: 10px;
  width: 100%;
  font-weight: bold;
  white-space: pre-line;
}

@media (max-width: 690px) {
  .experience-role {
    font-size: 12px;
  }
}

@media (min-width: 1200px) {
  .experience-role {
    font-size: 24px;
  }
}


.experience-list-header {
  font-weight: normal;
  margin: 0;
  font-size: 18px;
  margin: 10px 0;
}

li {
  list-style: none;
}


.experience-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.experience-image.hiya-logo {
  padding: 2px;
}


.surrey-uni-logo {
  -webkit-filter: invert(100%);
  /* Safari/Chrome */
  filter: invert(100%);
}

.experience-content-wrapper {
  width: 100%;
  font-size: 2vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
  max-width: 1200px;
}

.experience-info {
  font-size: 18px;
  border-top: 2px solid black;
  padding: 10px 0;
  white-space: pre-line;
}

@media (max-width: 690px) {
  .experience-info {
    font-size: 12px;
  }
  .project-tag, .experience-tag {
    font-size: 10px;
  }
}

@media (min-width: 1200px) {
  .experience-info {
    font-size: 20px;
  }
}

// @media (min-width: 1200px) {
//   .experience-info {
//     width: 500px;
//   }
// }



.experience-dates {
  font-size: 18px;
  width: 100%;
  margin-bottom: 10px;
}

@media (max-width: 690px) {
  .experience-dates {
    font-size: 12px;
  }
}

@media (min-width: 1200px) {
  .experience-dates {
    font-size: 18px;
  }
}

.clients-list {
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: normal;
  width: 100%;
}

@media (max-width: 690px) {
  .clients-list {
    font-size: 12px;
  }
}

@media (min-width: 1200px) {
  .clients-list {
    font-size: 20px;
  }
}


//* SKILLS

.skills-section {
  // background-color: #e00484;
  background-color: $colorF;
  color: white;
  z-index: 10;
  position: relative;
}

.skills-content-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 50px;
}

.skills-list {
  font-size: 30px;
  margin: 0 0 30px;
  padding: 0;
}

.skills-list li {
  display: flex;
  justify-content: center;
}



// //* EVENTS

// .events-section {
//   flex-direction: column;
//   z-index: 10;
//   position: relative; 
// }

// .event-image-wrapper {
//   height: auto;
//   margin: 20px;
// }

// .event-content-wrapper {
//   display: flex;
//   justify-content: center;
//   flex-wrap: wrap;
//   margin-bottom: 50px;
// }

// .event-image {
//   width: 35vw;
//   object-fit: cover;
// }

// .events-section {
//   background-color: #A2A3A5;
//   color: white;
// }

// .hiya-may-19 {
//   justify-self: flex-end;
// }


// //* CONTACT

// .contact-section {
//   height: 100vh;
//   flex-direction: column;
//   z-index: 10;
//   position: relative; 
//   background-color: #5edf34;
//   color: white;
// }

// .contact-links {
//   display: flex;
//   justify-content: center;
//   flex-direction: column;
//   align-items: center;
//   margin-top: 4vw;
// }

// .contact-links a {
//   color: white;
//   font-size: 2.8vw;
//   // padding: 6vw;
//   margin: 2vh;
// }

// .windows-image-wrapper {
//   height: auto;
//   width: 100%;
//   display: flex;
//   justify-content: center;
// }

.footer {
  display: flex;
  // height: 10%;
  position: fixed;
  bottom: 0;
  z-index: 10;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  pointer-events: none;
}

@media (max-width: 650px) {
  .scroll-down-wrapper {
    visibility: hidden;
  }
}

@media (max-width: 870px) {
  .back-to-top {
    visibility: hidden;
  }
}

// .windows-image {
//   height: 38vw;
//   justify-self: flex-end;
//   object-fit: cover;
// }

.back-to-top-wrapper,
.email-footer-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.footer-email,
.footer-email a {
  color: $colorA;
  background-color: black;
  font-size: 20px;
  // text-transform: uppercase;
  transition: .2s;
  pointer-events: auto;
}

// .footer-email a:hover {
//   color: $colorF;
//   transition: .2s;
// }

.footer-email a {
  display: flex;
  align-items: center;
}

.footer-email {
  padding: 6px;
  margin: 7px;
  display: flex;
  align-items: center;
  // border-radius: 7px;
  height: 37px;
  border: 2px solid $colorA;
}

.contact-label {
  margin: 0 0 0 8px;
}

.back-to-top {
  margin: 8px;
  cursor: pointer;
  pointer-events: auto;
}


.about-wrapper {
  width: 65%;
  display: flex;
  justify-content: center;
  margin-bottom: 6vw;
}

.about-text {
  font-size: 18px;
  margin: 0 0 50px;
  white-space: pre-line;
}

// @media (max-width: 690px) {
//   .about-text {
//     font-size: 12px;
//   }
// }

@media (min-width: 1200px) {
  .about-text {
    font-size: 20px;
    width: 800px;
  }
}

// .title-active,
// .title-active .bio-link {
//   transition: .2s;
//   color: transparent;
// }